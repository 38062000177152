var exports = {};

exports = function (hljs) {
  return {
    keywords: {
      built_in: "spawn spawn_link self",
      keyword: "after and andalso|10 band begin bnot bor bsl bsr bxor case catch cond div end fun if " + "let not of or orelse|10 query receive rem try when xor"
    },
    contains: [{
      className: "meta",
      begin: "^[0-9]+> ",
      relevance: 10
    }, hljs.COMMENT("%", "$"), {
      className: "number",
      begin: "\\b(\\d+#[a-fA-F0-9]+|\\d+(\\.\\d+)?([eE][-+]?\\d+)?)",
      relevance: 0
    }, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, {
      begin: "\\?(::)?([A-Z]\\w*(::)?)+"
    }, {
      begin: "->"
    }, {
      begin: "ok"
    }, {
      begin: "!"
    }, {
      begin: "(\\b[a-z'][a-zA-Z0-9_']*:[a-z'][a-zA-Z0-9_']*)|(\\b[a-z'][a-zA-Z0-9_']*)",
      relevance: 0
    }, {
      begin: "[A-Z][a-zA-Z0-9_']*",
      relevance: 0
    }]
  };
};

export default exports;